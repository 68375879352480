<template>
  <div class="job-openings">
    <div
      class="job-openings__content col-11 col-sm-10"
      data-aos="fade-up"
      data-aos-duration="500"
      data-aos-offset="300"
    >
      {{ jobOpenings.title }}
    </div>
    <div
      class="job-openings__container"
      data-aos="fade-up"
      data-aos-duration="500"
      data-aos-offset="300"
    >
      <div class="job-openings__items">
        <router-link
          class="job-openings__item"
          v-for="job in jobs"
          :to="`/recruitment/${job.id}`"
          :key="job.id"
        >
          <div class="job-openings__item--title">
            <h3>{{ job.name }}</h3>
            <p v-if="!!job.number_experience">{{ job.number_experience }}</p>
          </div>
          <div class="job-openings__item--sub">
            <div class="d-flex align-items-center">
              <img v-lazy="moneyIcon" alt="money" />
              <span class="ml-2">{{ job.salary }}</span>
            </div>
            <router-link :to="`/recruitment/${job.id}`">
              <div class="job-openings__item--btn">
                <svg
                  width="20"
                  height="16"
                  viewBox="0 0 20 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.33301 7C0.780723 7 0.333008 7.44772 0.333008 8C0.333008 8.55228 0.780723 9 1.33301 9V7ZM19.0123 8.70711C19.4029 8.31658 19.4029 7.68342 19.0123 7.29289L12.6484 0.928932C12.2579 0.538408 11.6247 0.538408 11.2342 0.928932C10.8436 1.31946 10.8436 1.95262 11.2342 2.34315L16.891 8L11.2342 13.6569C10.8436 14.0474 10.8436 14.6805 11.2342 15.0711C11.6247 15.4616 12.2579 15.4616 12.6484 15.0711L19.0123 8.70711ZM1.33301 9H18.3052V7H1.33301V9Z"
                    fill="white"
                  />
                </svg>
              </div>
            </router-link>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: 'Job-openings',
  data() {
    return {
      arrow: '/images/icons/home/arrow-right.svg',
      moneyIcon: '/images/icons/recruitment/money.svg',
      jobOpenings: this.$t('recruiment').jobOpenings
    };
  },
  methods: {},
  watch: {
    lang() {
      this.jobOpenings = this.$t('recruiment').jobOpenings;
    }
  },
  props: {
    jobs: {
      type: Array
    }
  },
  computed: {
    ...mapState({
      lang: (state) => state.langs.lang
    })
  }
};
</script>

<style lang="scss" scroped>
img {
  object-fit: cover;
}
.job-openings {
  width: 100%;
  padding: 140px 0 70px;
}
.job-openings__content {
  position: relative;
  font-weight: 700;
  font-size: 40px;
  color: var(--blue-text);
  margin: auto;
  margin-top: 40px;
  margin-bottom: 60px;
  z-index: 0;
  text-align: center;
}
.job-openings__container {
  position: relative;
  width: 100%;
  margin: auto;
  z-index: 0;
}
.job-openings__items {
  position: relative;
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 44px;
  width: 80%;
  margin: auto;
  z-index: 0;
  &::before {
    content: '';
    position: absolute;
    background: url('/images/bg/circle-title.svg');
    background-repeat: no-repeat;
    background-size: cover;
    width: 98px;
    height: 98px;
    z-index: -1;
    right: -10%;
    top: 0;
  }
}
.job-openings__item {
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 260px;
  padding: 36px 30px 28px;
  box-shadow: var(--box-shadow);
  border-radius: 25px;
  background: var(--white);
  transition: all 0.3s;
  overflow: hidden;
  &:hover {
    text-decoration: none;
  }
  &::after {
    content: '';
    width: 300px;
    height: 150px;
    background: url('/images/bg/circle-element.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    position: absolute;
    bottom: 0;
    left: -200px;
    transition: all 0.3s;
  }
  &::before {
    content: '';
    width: 300px;
    height: 150px;
    background: url('/images/bg/circle-element.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    position: absolute;
    top: 0;
    right: -200px;
    transition: all 0.3s;
  }
  &:hover {
    background: rgba(252, 119, 53, 1);
    h3 {
      color: var(--white);
    }
    span {
      color: var(--white);
    }
    p {
      color: var(--orange);
      background-color: #fff;
    }
    &::before {
      right: -50px;
    }
    &::after {
      left: -50px;
    }
    .job-openings__item--btn {
      background-color: #fff;
      path {
        fill: var(--orange);
      }
    }
  }
}
.job-openings__item--title {
  gap: 16px;
  h3 {
    color: var(--black-text);
    font-weight: 700;
    font-size: 24px;
  }
  & > p {
    display: inline-block;
    margin-top: 5px;
    padding: 4px 10px;
    font-size: 18px;
    color: #088cfc;
    background-color: #e4f4ff;
    border-radius: 10px;
  }
}
.job-openings__item--sub {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  & > span {
    color: var(--grey-text);
    font-weight: 700;
    font-size: 18px;
  }
}
.job-openings__item--btn {
  display: flex;
  justify-content: center;
  align-items: center;
  float: right;
  width: 52px;
  height: 52px;
  background-color: var(--blue-text);
  border-radius: 50%;
}

@media (max-width: 1024px) {
  .job-openings__items {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
@media (max-width: 768px) {
  .job-openings__items {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .job-openings__content {
    font-size: 32px;
  }
}
</style>
